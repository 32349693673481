import { memo, ReactNode } from 'react';
import { Provider } from 'react-redux';
import { store } from './store/configureStore';


type Props = {
  children: ReactNode;
};

function Component({ children }: Props) {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
}

export const Providers = memo<Props>(({ children }) => <Component>{children}</Component>);
