import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CreatePass } from 'Pages';

import { usePassCodeCreateMutation, usePassCodeQuery } from 'Pages/CreatePass/api/passApi';
import { PassCodeCreateData } from 'Pages/CreatePass/createPass.entity';

export const CreatePassScreen: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError, error } = usePassCodeQuery({ code: id });
  const [passCodeCreate] = usePassCodeCreateMutation();

  const handleSubmitForm = async (values: PassCodeCreateData) => {
    if (!data) {
      return;
    }
    try {
      await passCodeCreate({
        ...values,
        passId: data.passId,
      }).unwrap();

      navigate('/pass-status');
    } catch {}
  };

  return <CreatePass error={error} isError={isError} isLoading={isLoading} data={data} onSubmit={handleSubmitForm} />;
};
